import { render, staticRenderFns } from "./LessonBanks.vue?vue&type=template&id=164b966c&scoped=true&"
import script from "./LessonBanks.vue?vue&type=script&lang=ts&"
export * from "./LessonBanks.vue?vue&type=script&lang=ts&"
import style0 from "./LessonBanks.vue?vue&type=style&index=0&id=164b966c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "164b966c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VBtn,VCard,VCardTitle,VCol,VDivider,VHover,VIcon,VList,VListItem,VListItemGroup,VListItemTitle,VMenu,VRow,VSheet,VSlideGroup,VSlideItem,VSubheader})


/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('164b966c')) {
      api.createRecord('164b966c', component.options)
    } else {
      api.reload('164b966c', component.options)
    }
    module.hot.accept("./LessonBanks.vue?vue&type=template&id=164b966c&scoped=true&", function () {
      api.rerender('164b966c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/LessonBanks.vue"
export default component.exports