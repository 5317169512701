


































































































































































































































































































































































import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import LessonListsTable from '@/components/lessonlists/LessonListsTable.vue';
import Confirm from './../../components/core/Confirm.vue';
import ld from 'lodash';
import draggable from 'vuedraggable';
import moment from 'moment';
import LessonServices from '@/services/lesson-services';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import { PlanbookStorage } from '@/common/planbook-storage';

const classes = namespace('classes');
const settings = namespace('settings');
const lessonlists = namespace('lessonlists');
const lessons = namespace('lessons');
const units = namespace('units');
const plans = namespace('plans');
@Component({
  mixins: [PageLifeCycleMixin],
  components: {
    LessonListsTable,
    draggable
  }
})
export default class LessonBanks extends Vue {
  search = '';
  localTableLessonBankItems: any[] = [];
  editMode = false;
  selectedRows: any = [];
  selectedItem = false;
  selectedUnits = this.$t('allUnitsLabel');
  isStandardsHovered = false;
  localSlideGroupModel = null;
  localIsMenuOverlapping = false;
  lastBrowserWidth = 0;

  $refs!: {
    confirm: Confirm,
    lessonListsTable: LessonListsTable,
    lessonBankList: any,
    lessonBankButton: any,
    districtBankList: any,
    districtBankButton: any,
    unitList: any,
    unitButton: any
  }

  @units.State
  units!: Array<any>;

  @units.Action
  loadUnits!: (params?: any) => Promise<any>;

  @units.Action('deleteUnitsFromLessonlist')
  deleteUnits!: (params?: any) => Promise<any>;

  @classes.State
  lessonBanks!: any;

  @classes.State
  classUnits!: any;

  @classes.Getter('getLessonBankItems')
  lessonBankItems!: Array<any>;

  @classes.Getter('getDistrictLessonBankItems')
  districtLessonBankItems!: Array<any>;

  @classes.Getter
  getClassLessonLayoutId!: (classId: number) => number;

  @settings.Getter('getSharedClasses')
  sharedClass!: any[];

  @settings.Action
  reloadSettings!: (params?: any) => Promise<any>;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter('getLessonRetrieval')
  lessonRetrieval!: any;

  @settings.Getter('getUserType')
  userType!: string;

  @settings.Getter('getSettings')
  settings!: any;

  @settings.Getter('getBrowserWidth')
  browserWidth!: number;

  @lessons.Getter
  createNewLesson!: () => any;

  @lessons.Action('init')
  initLesson!: (params?: any) => Promise<any>;

  @lessonlists.Mutation
  setLessonBanksView!: (value: any) => void;

  @lessonlists.Action
  getListLessons!: (param?: any) => Promise<any>;

  @lessonlists.Mutation
  resetLessonBankList!: () => void;

  @lessonlists.Action
  changeSequence!: (param?: any) => Promise<any>;

  @lessonlists.Action
  deleteLessonBank!: (param?: any) => Promise<any>;

  @lessonlists.Getter('getSelectedLessonsInLessonBanks')
  selectedLessonBankLessons!: any[];

  @lessonlists.Getter('getSelectedLessonBankClassId')
  selectedLessonBankClassId!: number;

  @lessonlists.Getter('getLessonBanksView')
  lessonBanksView!: string;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  @plans.Action('init')
  initPlans!: (param?: any) => Promise<any>;

  @plans.Action
  loadPlans!: (param?: any) => Promise<any>;

  @Watch('browserWidth')
  onBrowserWidthChanged(val:any) {
    this.isMenuOverlapping(val);
  }

  get firstLoad() {
    return this.$store.state.settings.firstLoad;
  }

  get haveUnits() {
    return this.$store.state.units.haveUnits;
  }

  set haveUnits(value: boolean) {
    this.$store.commit('units/setHaveUnits', value);
  }

  get tableLessonBankItems() {
    return this.localTableLessonBankItems;
  }

  set tableLessonBankItems(val:any) {
    this.localTableLessonBankItems = val;
  }

  get isThereSelectedBanks() {
    if (this.selectedLessonBankClassId !== 0) {
      return true;
    } else {
      return false;
    }
  }

  get getSharedClass():any[] {
    const sharedClasses:any = [];
    Object.entries(this.sharedClass).forEach((sharedClass: any) => {
      if (sharedClass[1].yearId === 0) {
        sharedClass[1].text = this.truncate(sharedClass[1].className, { length: 30 });
        sharedClasses.push(sharedClass[1]);
      }
    });
    return sharedClasses.sort((sc1:any, sc2:any) => {
      return sc1.className.localeCompare(sc2.className);
    });
  }

  get allAvailableLessonBanks() {
    const allAvailableLessonBanks: any[] = ld.cloneDeep(this.lessonBankItems);
    Object.entries(this.sharedClass).forEach((sharedClass: any) => {
      allAvailableLessonBanks.push({
        value: sharedClass[1].classId,
        text: sharedClass[1].className,
        isSharedClass: true
      });
    });
    return allAvailableLessonBanks;
  }

  get truncate() {
    return ld.truncate;
  }

  get isCurrentClassShared() {
    let isCurrentClassShared = false;
    this.allAvailableLessonBanks.forEach((element: any) => {
      if (
        element.value === this.selectedLessonBankClassId ||
          element.classId === this.selectedLessonBankClassId
      ) {
        if (element.isSharedClass) {
          isCurrentClassShared = true;
        } else {
          isCurrentClassShared = false;
        }
      }
    });
    return isCurrentClassShared;
  }

  get currentTeacherId(): number {
    if (this.$currentUser.isTeacher) {
      return +this.$store.state.settings.userId;
    } else {
      return +this.$store.state.settings.currentTeacherId;
    }
  }

  get updatedSettings(): any {
    return this.$store.state.settings.updatedSettings;
  }

  get yearId(): number {
    return +this.$store.state.settings.currentYearId;
  }

  get fetchWeekSize(): number {
    return this.getNumber('fetchWeekSize', +this.lessonRetrieval.fetchWeekSize);
  }

  get sharedClassUnits():any[] {
    const that = this;
    let sharedUnit:any[] = [];
    Object.entries(this.sharedClass).forEach((sharedClass: any) => {
      if (+sharedClass[0] === this.selectedLessonBankClassId) {
        sharedUnit = sharedClass[1].units;
      }
    });
    return sharedUnit
      .filter(u => {
        return !that.localClassUnits.some(cu => +cu.unitId === +u.unitId);
      })
      .sort((u1, u2) => {
        return u1.unitNum.localeCompare(u2.unitNum);
      });
  }

  get truncatedLength() {
    if (this.$vuetify.breakpoint.lg) {
      return 15;
    } else {
      return 30;
    }
  }

  get localLessonBankName() {
    if (this.isDistrictBank) return this.$t('selectBankLabel');
    return this.truncate((this.lessonBankItems.filter((c: any) => c.value === this.selectedLessonBankClassId)[0] ||
    this.getSharedClass.filter((c: any) => c.classId === this.selectedLessonBankClassId)[0] ||
    { text: this.$t('selectBankLabel') }).text, { length: this.truncatedLength });
  }

  get localDistrictLessonBankName() {
    if (!this.isDistrictBank) return this.$t('selectDistrictBankLabel');
    return this.truncate((this.districtLessonBankItems.filter((c: any) => c.value === this.selectedLessonBankClassId)[0] || { text: this.$t('selectDistrictBankLabel') }).text, { length: this.truncatedLength });
  }

  get isDistrictBank() {
    return this.districtLessonBankItems.some(d => +d.value === +this.selectedLessonBankClassId);
  }

  get localClassUnits():any[] {
    const unitsArray:any[] = [];
    this.units.forEach((unit: any) => {
      if (+unit.subjectId === this.selectedLessonBankClassId) {
        unitsArray.push(unit);
      }
    });
    return unitsArray.sort((u1, u2) => {
      return u1.unitNum.localeCompare(u2.unitNum);
    });
  }

  get isAllTitles() {
    if (this.lessonBanksView === this.$t('allTitlesLabel')) {
      return true;
    } else {
      return false;
    }
  }

  get isAllowedToCreateSchoolLessonBanks() {
    return this.$currentUser.isTeacher && this.userInfo.schoolSettings.primarySchool && this.userInfo.schoolSettings.primarySchool.allowCreateLessonBanks;
  }

  get lessonUniqueIdentifier() {
    return LessonServices.getLessonUniqueIdentifier;
  }

  isObjectNotEmpty(obj: any) {
    return obj && Object.keys(obj).length > 0;
  }

  getNumber(key: string, defaultValue: any) {
    if (Object.prototype.hasOwnProperty.call(this.updatedSettings, key)) {
      return +this.updatedSettings[key];
    }
    return +defaultValue;
  }

  doInit() {
    CommonUtils.showLoading();
    this.loadUnits().then(() => {
      this.initialize();
    });
  }

  mounted() {
    const that = this;
    this.$nextTick(() => {
      that.$eventBus.$on('lessonHasBeenSave', () => {
        CommonUtils.hideLoading();
        if (that.$refs.lessonListsTable) {
          that.$refs.lessonListsTable.localInfiniteScrollResetAllowed = false;
        }
        if (that.selectedUnits === that.$t('allUnitsLabel')) {
          that.tableLessonBankItems = that.selectedLessonBankLessons;
        } else {
          that.tableLessonBankItems = that.selectedLessonBankLessons.filter((lesson:any) => lesson.unitNum === that.selectedUnits);
        }
      });
      that.$eventBus.$on('resetLessonBankData', (params?:any) => {
        let showLoading = true;
        let autoScrollBottom = false;
        let autoSave = false;
        let lessonDeleted = false;
        if (params) {
          autoScrollBottom = params.autoScrollBottom;
          autoSave = params.autoSave;
          lessonDeleted = params.lessonDeleted;
          if (autoSave) showLoading = false;
        }
        that.changeLessonBank(that.selectedLessonBankClassId, showLoading, false, autoScrollBottom, autoSave, lessonDeleted);
      });
      that.$eventBus.$on('userModeChanged', this.doInit);
      that.$eventBus.$on('refreshUnits', () => {
        that.doInit();
      });
      that.$eventBus.$emit('openDefaultSubPage');
      that.$eventBus.$on('selectUnit', this.filterUnits);
      that.showAllUnits();
      // Lessons In Units Actions
      if (PlanbookStorage.get('unitViewLessonsParams')) {
        if (+PlanbookStorage.get('unitViewLessonsParams').subjectId !== this.selectedLessonBankClassId) {
          const that = this;
          CommonUtils.showLoading();
          that.changeLessonBank(+PlanbookStorage.get('unitViewLessonsParams').subjectId, true, true)
            .then(() => {
              setTimeout(() => {
                that.filterUnits(that.units.filter(u => (+u.unitId || +u.id) === +PlanbookStorage.get('unitViewLessonsParams').unitId)[0]);
                PlanbookStorage.set('unitViewLessonsParams', undefined);
              }, 500);
            });
        } else {
          setTimeout(() => {
            this.filterUnits(that.units.filter(u => (+u.unitId || +u.id) === +PlanbookStorage.get('unitViewLessonsParams').unitId)[0]);
            PlanbookStorage.set('unitViewLessonsParams', undefined);
            CommonUtils.hideLoading();
          }, 500);
        }
      }
    });
  }

  isMenuOverlapping(browserWidth?:number) {
    if (this.lastBrowserWidth !== 0 && (browserWidth || 0) < this.lastBrowserWidth) {
      this.localIsMenuOverlapping = true;
      return;
    }
    if ((browserWidth || 0) > this.lastBrowserWidth) {
      this.localIsMenuOverlapping = false;
      this.lastBrowserWidth = 0;
      return;
    }
    setTimeout(() => {
      const toolbarTitle:any = document.getElementById('toolbarTitle');
      const titleRect = toolbarTitle.getBoundingClientRect();
      const unitsFilterMenu:any = document.getElementById('unitsFilterMenu');
      if (unitsFilterMenu) {
        const unitsFilterMenuRect = unitsFilterMenu.getBoundingClientRect();
        if (
          titleRect.top < unitsFilterMenuRect.bottom &&
            titleRect.bottom > unitsFilterMenuRect.top &&
            titleRect.left < unitsFilterMenuRect.right &&
            titleRect.right > unitsFilterMenuRect.left
        ) {
          this.lastBrowserWidth = this.browserWidth;
          this.localIsMenuOverlapping = true;
          return true;
        }
        this.localIsMenuOverlapping = false;
        return false;
      } else {
        this.localIsMenuOverlapping = false;
        return false;
      }
    }, 50);
    this.localIsMenuOverlapping = false;
    return false;
  }

  async created() {
    if (!this.haveUnits) {
      await this.loadUnits();
      this.haveUnits = true;
    }
    if (this.firstLoad) {
      return Promise.resolve().then(() => {
        return this.initPlans();
      })
        .then(() => {
          return this.loadPlans();
        });
    }
  }

  destroyed() {
    this.$eventBus.$off('lessonHasBeenSave');
    this.$eventBus.$off('userModeChanged', this.doInit);
    this.$eventBus.$off('resetLessonBankData');
    this.$eventBus.$off('refreshUnits');
    this.$eventBus.$off('selectUnit', this.filterUnits);
  }

  filterUnits(unit:any) {
    // if (this.$refs.lessonListsTable) {
    //   this.$refs.lessonListsTable.localInfiniteScrollResetAllowed = true;
    // }
    this.selectedUnits = unit.unitNum || unit.num;
    this.tableLessonBankItems = this.selectedLessonBankLessons;
    this.tableLessonBankItems = this.tableLessonBankItems.filter((lesson:any) => lesson.unitNum === unit.unitNum);
  }

  showAllUnits() {
    this.selectedUnits = this.$t('allUnitsLabel');
    this.tableLessonBankItems = this.selectedLessonBankLessons;
  }

  private initialize() {
    const that = this;
    this.tableLessonBankItems = this.selectedLessonBankLessons;
    that.showAllUnits();
    CommonUtils.hideLoading();
  }

  async changeLessonBank(id: number, showLoading:boolean, resetList:boolean, autoScrollBottom?:boolean, autoSave?:boolean, lessonDeleted?:boolean) {
    if (this.$refs.lessonListsTable) {
      this.$refs.lessonListsTable.localInfiniteScrollResetAllowed = resetList;
      this.$refs.lessonListsTable.autoScrollBottom = false;
      this.$refs.lessonListsTable.autoSave = false;
      if (autoScrollBottom) {
        this.$refs.lessonListsTable.localInfiniteScrollResetAllowed = true;
        this.$refs.lessonListsTable.autoScrollBottom = true;
      }
      if (autoSave) {
        this.$store.commit('lessons/setSaving', true);
        this.$refs.lessonListsTable.autoSave = true;
      }
      if (lessonDeleted) {
        this.$refs.lessonListsTable.lessonDeleted = true;
      }
    }
    const that = this;
    if (showLoading) {
      CommonUtils.showLoading();
    }
    if (!this.haveUnits) {
      await this.loadUnits();
      this.haveUnits = true;
    }
    return this.getBanksData(id).then(() => {
      if (resetList) {
        that.showAllUnits();
      } else {
        if (that.selectedUnits === that.$t('allUnitsLabel')) {
          that.showAllUnits();
        } else {
          that.tableLessonBankItems = that.selectedLessonBankLessons.filter((lesson:any) => lesson.unitNum === that.selectedUnits);
        }
      }
      if (showLoading) {
        CommonUtils.hideLoading();
      }
      if (autoSave) that.$store.commit('lessons/setSaving', false);
    });
  }

  private getBanksData(id:number) {
    const jsonPayload:any = {
      classId: id,
      allLessons: true,
      weeks: false,
      fetchDetails: true,
      userType: this.userType,
      lessonView: 'bank'
    };
    return this.getListLessons(jsonPayload);
  }

  moveSequence(evt: any, lessonBankItems: any) {
    CommonUtils.showLoading();
    const sequence:any[] = [];
    lessonBankItems.forEach((element:any) => {
      sequence.push(element.classId + 'X' + this.formatDateToFilter(element.date) + 'Z' + element.extraLesson)
    });
    this.changeSequence({
      classId: this.selectedLessonBankClassId,
      sequence: sequence
    }).then(() => {
      this.changeLessonBank(this.selectedLessonBankClassId, true, false);
    });
  }

  private formatDateToFilter(date:Date) {
    const mm = moment(date, 'MM/DD/YYYY').format('MM');
    const dd = moment(date, 'MM/DD/YYYY').format('DD');
    const yyyy = moment(date, 'MM/DD/YYYY').format('YYYY');
    return mm + 'd' + dd + 'd' + yyyy;
  }

  private addLesson() {
    const newLessonData = this.createNewLesson();
    newLessonData.classId = this.selectedLessonBankClassId;
    newLessonData.layoutId = this.getClassLessonLayoutId(this.selectedLessonBankClassId);
    this.initLesson({ data: newLessonData });
    this.$eventBus.$emit('openSubPage', {
      type: 'lesson',
      width: 600,
      modal: false,
      input: {
        action: 'A',
        loadData: true,
        data: newLessonData,
        lessonListsView: true
      }
    });
  }

  private importCSV() {
    this.$eventBus.$emit('openSubPage', {
      type: 'csv',
      width: 600,
      modal: true,
      input: {
        type: this.$t('lessonBanksLabel'),
        importWhat: 'lessons'
      }
    });
  }

  addOrEditUnits(item:any) {
    if (CommonUtils.isEmpty(Object.keys(item))) {
      item = { subjectId: this.selectedLessonBankClassId };
    }
    this.$eventBus.$emit('openSubPage', {
      type: 'unit',
      width: 600,
      modal: true,
      input: item
    });
  }

  async deleteUnit(unit:any, reloadSettings:boolean) {
    return this.$refs.confirm.confirm({
      title: this.$t('deleteUnitsLabel'),
      text: this.$t('confirmDeleteUnitsMg'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        CommonUtils.showLoading();
        return this.deleteUnits({
          action: 'D',
          unitId: unit.unitId,
          subjectId: unit.subjectId,
          userMode: this.userMode
        }).then(() => {
          if (reloadSettings) {
            return this.reloadSettings();
          } else {
            return Promise.resolve();
          }
        }).finally(() => {
          CommonUtils.hideLoading();
        });
      }
    });
  }

  doDeleteLessonBank(classId:any, className:any, lessonBankType:any) {
    return this.$refs.confirm.confirm({
      title: this.$t('lessonBanksLabel'),
      text: this.$t('lessonBankDeleteConfirmMessage', { s: className }),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result:any) => {
      if (result === 1) {
        CommonUtils.showLoading();
        this.deleteLessonBank({
          classId: classId,
          lessonBankType: lessonBankType,
          userMode: this.userMode,
          schoolId: this.settings.schoolSettings.schoolId
        }).then(() => {
          if (this.selectedLessonBankClassId === classId) {
            this.resetLessonBankList();
            this.tableLessonBankItems = this.selectedLessonBankLessons;
          }
          CommonUtils.hideLoading();
        })
        return Promise.resolve(false);
      } else {
        return Promise.resolve(true);
      }
    });
  }

  editOrAddBank(classId:any, className:any, actionCode:any, lessonBankData?:any, schoolOrDistrict?:any) {
    if (actionCode === 'ULB' || actionCode === 'UDLB') {
      const layoutId = this.$store.getters['classes/getClassLessonLayoutId'](classId);
      this.$store.commit('lessonlists/setLessonBanksTitle', className);
      this.$store.commit('lessonlists/setLessonBankLayoutId', layoutId);
    }

    this.$eventBus.$emit('openSubPage', {
      type: 'lessonBanks',
      width: 600,
      modal: true,
      input: {
        action: actionCode,
        classId: classId,
        data: lessonBankData,
        schoolOrDistrict: schoolOrDistrict
      }
    });
  }

  focusBankEditButton() {
    try {
      const i = this.$refs.lessonBankList.$children.findIndex((listItem: any) => {
        return listItem.$el.classList.contains('v-list-item--highlighted');
      })
      const index = i - ((this.isAllowedToCreateSchoolLessonBanks) ? 2 : 1);
      if (index >= 0) {
        (this.$refs as any)['editButton' + (index)][0].$el.focus();
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusBankDeleteButton() {
    try {
      const i = this.$refs.lessonBankList.$children.findIndex((listItem: any) => {
        return listItem.$el.classList.contains('v-list-item--highlighted');
      })
      const index = i - ((this.isAllowedToCreateSchoolLessonBanks) ? 2 : 1);
      if (index >= 0) {
        (this.$refs as any)['deleteButton' + (index)][0].$el.focus();
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusLessonBankList() {
    try {
      this.$refs.lessonBankButton.$el.focus();
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusDistrictEditButton() {
    try {
      const index = this.$refs.districtBankList.$children.findIndex((listItem: any) => {
        return listItem.$el.classList.contains('v-list-item--highlighted');
      })
      if (index >= 0) {
        (this.$refs as any)['dEditButton' + (index - 1)][0].$el.focus();
      }
    } catch (e) {}
  }

  focusDistrictDeleteButton() {
    try {
      const index = this.$refs.districtBankList.$children.findIndex((listItem: any) => {
        return listItem.$el.classList.contains('v-list-item--highlighted');
      })
      if (index >= 0) {
        (this.$refs as any)['dDeleteButton' + (index - 1)][0].$el.focus();
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusDistrictBankList() {
    try {
      this.$refs.districtBankButton.$el.focus();
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusUnitEditButton() {
    try {
      const index = this.$refs.unitList.$children.findIndex((listItem: any) => {
        return listItem.$el.classList.contains('v-list-item--highlighted');
      }) - 1;
      if (index >= 0) {
        (this.$refs as any)['uEditButton' + (index - 1)][0].$el.focus();
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusUnitDeleteButton() {
    try {
      const index = this.$refs.unitList.$children.findIndex((listItem: any) => {
        return listItem.$el.classList.contains('v-list-item--highlighted');
      }) - 1;
      if (index >= 0) {
        (this.$refs as any)['uDeleteButton' + (index - 1)][0].$el.focus();
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusUnitList() {
    try {
      this.$refs.unitButton.$el.focus();
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }
}
