var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    { staticClass: "lessonBanks", attrs: { color: "#ffffff" } },
    [
      _vm.$vuetify.breakpoint.mdAndDown || _vm.localIsMenuOverlapping
        ? _c(
            "v-sheet",
            { staticClass: "mx-auto", attrs: { elevation: "0" } },
            [
              _c(
                "v-slide-group",
                {
                  staticClass: "py-2",
                  attrs: { "center-active": "" },
                  model: {
                    value: _vm.localSlideGroupModel,
                    callback: function($$v) {
                      _vm.localSlideGroupModel = $$v
                    },
                    expression: "localSlideGroupModel"
                  }
                },
                [
                  _vm.userType === "D"
                    ? _c(
                        "v-slide-item",
                        { key: 0 },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                transition: "scroll-y-transition",
                                "offset-y": "",
                                "max-height": "500"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                ref: "districtBankButton",
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "primary",
                                                  outlined: "",
                                                  rounded: "",
                                                  small: ""
                                                },
                                                on: {
                                                  keyup: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "right",
                                                        39,
                                                        $event.key,
                                                        ["Right", "ArrowRight"]
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    if (
                                                      "button" in $event &&
                                                      $event.button !== 2
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.focusDistrictEditButton()
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.localDistrictLessonBankName
                                                )
                                              )
                                            ]),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                attrs: { small: "" }
                                              },
                                              [_vm._v("fal fa-chevron-down")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                859488687
                              )
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item-group",
                                    {
                                      ref: "districtBankList",
                                      attrs: {
                                        color: "primary",
                                        "aria-label": _vm.$t(
                                          "districtLessonBanksLabel"
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.editOrAddBank(
                                                null,
                                                null,
                                                "DLB"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("fal fa-circle-plus")]
                                          ),
                                          _c(
                                            "v-list-item-title",
                                            { staticClass: "drop-down-list" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "addDistrictLessonBankLabel"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._l(
                                        _vm.districtLessonBankItems,
                                        function(districtLessonBank, index) {
                                          return _c(
                                            "v-hover",
                                            { key: districtLessonBank.value },
                                            [
                                              _c(
                                                "v-list-item",
                                                {
                                                  class: {
                                                    "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                                      _vm.selectedLessonBankClassId ==
                                                      districtLessonBank.value
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.changeLessonBank(
                                                        districtLessonBank.value,
                                                        true,
                                                        true
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "drop-down-list"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            districtLessonBank.text
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      ref:
                                                        "dEditButton" + index,
                                                      refInFor: true,
                                                      staticClass: "ml-2",
                                                      attrs: {
                                                        icon: "",
                                                        small: "",
                                                        "aria-label": _vm.$t(
                                                          "editLabel"
                                                        )
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.editOrAddBank(
                                                            districtLessonBank.value,
                                                            districtLessonBank.text,
                                                            "UDLB",
                                                            districtLessonBank,
                                                            "D"
                                                          )
                                                        },
                                                        keyup: [
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "right",
                                                                39,
                                                                $event.key,
                                                                [
                                                                  "Right",
                                                                  "ArrowRight"
                                                                ]
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            if (
                                                              "button" in
                                                                $event &&
                                                              $event.button !==
                                                                2
                                                            ) {
                                                              return null
                                                            }
                                                            return _vm.focusDistrictDeleteButton.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "left",
                                                                37,
                                                                $event.key,
                                                                [
                                                                  "Left",
                                                                  "ArrowLeft"
                                                                ]
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            if (
                                                              "button" in
                                                                $event &&
                                                              $event.button !==
                                                                0
                                                            ) {
                                                              return null
                                                            }
                                                            return _vm.focusDistrictBankList.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "up",
                                                                38,
                                                                $event.key,
                                                                [
                                                                  "Up",
                                                                  "ArrowUp"
                                                                ]
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return _vm.focusDistrictBankList.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "down",
                                                                40,
                                                                $event.key,
                                                                [
                                                                  "Down",
                                                                  "ArrowDown"
                                                                ]
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return _vm.focusDistrictBankList.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          }
                                                        ],
                                                        keydown: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          ) {
                                                            return null
                                                          }
                                                          $event.stopPropagation()
                                                          return _vm.editOrAddBank(
                                                            districtLessonBank.value,
                                                            districtLessonBank.text,
                                                            "UDLB",
                                                            districtLessonBank,
                                                            "D"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fal fa-pencil"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      ref:
                                                        "dDeleteButton" + index,
                                                      refInFor: true,
                                                      staticClass: "ml-2",
                                                      attrs: {
                                                        color: "error",
                                                        icon: "",
                                                        small: "",
                                                        "aria-label": _vm.$t(
                                                          "deleteLabel"
                                                        )
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.doDeleteLessonBank(
                                                            districtLessonBank.value,
                                                            districtLessonBank.text,
                                                            "D"
                                                          )
                                                        },
                                                        keyup: [
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "left",
                                                                37,
                                                                $event.key,
                                                                [
                                                                  "Left",
                                                                  "ArrowLeft"
                                                                ]
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            if (
                                                              "button" in
                                                                $event &&
                                                              $event.button !==
                                                                0
                                                            ) {
                                                              return null
                                                            }
                                                            return _vm.focusDistrictEditButton.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "up",
                                                                38,
                                                                $event.key,
                                                                [
                                                                  "Up",
                                                                  "ArrowUp"
                                                                ]
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return _vm.focusDistrictBankList.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "down",
                                                                40,
                                                                $event.key,
                                                                [
                                                                  "Down",
                                                                  "ArrowDown"
                                                                ]
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return _vm.focusDistrictBankList.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            $event.stopPropagation()
                                                            return _vm.doDeleteLessonBank(
                                                              districtLessonBank.value,
                                                              districtLessonBank.text,
                                                              "D"
                                                            )
                                                          }
                                                        ]
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fal fa-trash-alt"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-slide-item",
                    { key: 1 },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            transition: "scroll-y-transition",
                            "offset-y": "",
                            "max-height": "500"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            ref: "lessonBankButton",
                                            staticClass: "ml-2",
                                            attrs: {
                                              color: "primary",
                                              outlined: "",
                                              rounded: "",
                                              small: ""
                                            },
                                            on: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "right",
                                                    39,
                                                    $event.key,
                                                    ["Right", "ArrowRight"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                if (
                                                  "button" in $event &&
                                                  $event.button !== 2
                                                ) {
                                                  return null
                                                }
                                                return _vm.focusBankEditButton()
                                              }
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.localLessonBankName)
                                          )
                                        ]),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "ml-2",
                                            attrs: { small: "" }
                                          },
                                          [_vm._v("fal fa-chevron-down")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            26790535
                          )
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item-group",
                                {
                                  ref: "lessonBankList",
                                  attrs: {
                                    color: "primary",
                                    "aria-label": _vm.$t("lessonBanksLabel")
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.editOrAddBank(
                                            null,
                                            null,
                                            "LB"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { small: "" }
                                        },
                                        [_vm._v("fal fa-circle-plus")]
                                      ),
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "drop-down-list" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("addLessonBankLabel"))
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.isAllowedToCreateSchoolLessonBanks
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.editOrAddBank(
                                                null,
                                                null,
                                                "SLB"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("fal fa-circle-plus")]
                                          ),
                                          _c(
                                            "v-list-item-title",
                                            { staticClass: "drop-down-list" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "addSchoolLessonBankLabel"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._l(_vm.lessonBankItems, function(
                                    lessonBank,
                                    index
                                  ) {
                                    return _c(
                                      "v-hover",
                                      { key: lessonBank.value },
                                      [
                                        _c(
                                          "v-list-item",
                                          {
                                            class: {
                                              "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                                _vm.selectedLessonBankClassId ==
                                                lessonBank.value
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.changeLessonBank(
                                                  lessonBank.value,
                                                  true,
                                                  true
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-list-item-title",
                                              { staticClass: "drop-down-list" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.truncate(
                                                        lessonBank.text,
                                                        { length: 30 }
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                ref: "editButton" + index,
                                                refInFor: true,
                                                staticClass: "ml-2",
                                                attrs: {
                                                  icon: "",
                                                  small: "",
                                                  "aria-label": _vm.$t(
                                                    "editLabel"
                                                  )
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.editOrAddBank(
                                                      lessonBank.value,
                                                      lessonBank.text,
                                                      "ULB",
                                                      lessonBank,
                                                      "S"
                                                    )
                                                  },
                                                  keyup: [
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "right",
                                                          39,
                                                          $event.key,
                                                          [
                                                            "Right",
                                                            "ArrowRight"
                                                          ]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      if (
                                                        "button" in $event &&
                                                        $event.button !== 2
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusBankDeleteButton.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "left",
                                                          37,
                                                          $event.key,
                                                          ["Left", "ArrowLeft"]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      if (
                                                        "button" in $event &&
                                                        $event.button !== 0
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusLessonBankList.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "up",
                                                          38,
                                                          $event.key,
                                                          ["Up", "ArrowUp"]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusLessonBankList.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "down",
                                                          40,
                                                          $event.key,
                                                          ["Down", "ArrowDown"]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusLessonBankList.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    }
                                                  ],
                                                  keydown: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    $event.stopPropagation()
                                                    return _vm.editOrAddBank(
                                                      lessonBank.value,
                                                      lessonBank.text,
                                                      "ULB",
                                                      lessonBank,
                                                      "S"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("fal fa-pencil")]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                ref: "deleteButton" + index,
                                                refInFor: true,
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "error",
                                                  icon: "",
                                                  small: "",
                                                  "aria-label": _vm.$t(
                                                    "deleteLabel"
                                                  )
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.doDeleteLessonBank(
                                                      lessonBank.value,
                                                      lessonBank.text,
                                                      "S"
                                                    )
                                                  },
                                                  keyup: [
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "left",
                                                          37,
                                                          $event.key,
                                                          ["Left", "ArrowLeft"]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      if (
                                                        "button" in $event &&
                                                        $event.button !== 0
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusBankEditButton.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "up",
                                                          38,
                                                          $event.key,
                                                          ["Up", "ArrowUp"]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusLessonBankList.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "down",
                                                          40,
                                                          $event.key,
                                                          ["Down", "ArrowDown"]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusLessonBankList.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      $event.stopPropagation()
                                                      return _vm.doDeleteLessonBank(
                                                        lessonBank.value,
                                                        lessonBank.text,
                                                        "S"
                                                      )
                                                    }
                                                  ]
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("fal fa-trash-alt")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _vm.userType !== "D" && _vm.userMode !== "A"
                                    ? _c(
                                        "div",
                                        [
                                          _c("v-divider", {
                                            staticClass: "mt-5"
                                          }),
                                          _c(
                                            "v-subheader",
                                            { staticClass: "justify-center" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "sharedLessonBanksLabel"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c("v-divider", {
                                            staticClass: "mb-5"
                                          }),
                                          _vm._l(_vm.getSharedClass, function(
                                            sharedLessonBank,
                                            index
                                          ) {
                                            return _c(
                                              "v-list-item",
                                              {
                                                key: index,
                                                on: {
                                                  click: function($event) {
                                                    return _vm.changeLessonBank(
                                                      sharedLessonBank.classId,
                                                      true,
                                                      true
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  {
                                                    staticClass:
                                                      "drop-down-list"
                                                  },
                                                  [
                                                    sharedLessonBank.districtId !==
                                                      0 &&
                                                    sharedLessonBank.districtId
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "tDistrictBank"
                                                          },
                                                          [_vm._v("D")]
                                                        )
                                                      : sharedLessonBank.schoolId !==
                                                          0 &&
                                                        sharedLessonBank.schoolId
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "tSchoolBank"
                                                          },
                                                          [_vm._v("S")]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "tTeacherBank"
                                                          },
                                                          [_vm._v("T")]
                                                        ),
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            sharedLessonBank.className
                                                          )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isThereSelectedBanks
                    ? _c(
                        "v-slide-item",
                        { key: 2 },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                transition: "scroll-y-transition",
                                "offset-y": "",
                                "max-height": "500"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                ref: "unitButton",
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "primary",
                                                  outlined: "",
                                                  rounded: "",
                                                  small: ""
                                                },
                                                on: {
                                                  keyup: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "right",
                                                        39,
                                                        $event.key,
                                                        ["Right", "ArrowRight"]
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    if (
                                                      "button" in $event &&
                                                      $event.button !== 2
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.focusUnitEditButton()
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(_vm.selectedUnits))
                                            ]),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                attrs: { small: "" }
                                              },
                                              [_vm._v("fal fa-chevron-down")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2845657751
                              )
                            },
                            [
                              _c(
                                "v-list",
                                { ref: "unitList" },
                                [
                                  !_vm.isCurrentClassShared ||
                                  (_vm.$currentUser.isDistrictAdmin &&
                                    _vm.isDistrictBank) ||
                                  _vm.$currentUser.isAdmin
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.addOrEditUnits({})
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("fal fa-circle-plus")]
                                          ),
                                          _c(
                                            "v-list-item-title",
                                            { staticClass: "drop-down-list" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("addUnitLabel"))
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.showAllUnits()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "drop-down-list" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("allUnitsLabel")) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._l(_vm.sharedClassUnits, function(unit) {
                                    return _c(
                                      "v-hover",
                                      { key: unit.unitId },
                                      [
                                        _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.filterUnits(unit)
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-list-item-title",
                                              { staticClass: "drop-down-list" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(unit.unitNum) +
                                                        " - " +
                                                        _vm._s(unit.unitTitle)
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                ref: "uEditButton" + _vm.index,
                                                refInFor: true,
                                                staticClass: "ml-2",
                                                attrs: {
                                                  icon: "",
                                                  small: "",
                                                  "aria-label": _vm.$t(
                                                    "editLabel"
                                                  )
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.addOrEditUnits(
                                                      unit
                                                    )
                                                  },
                                                  keydown: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    $event.stopPropagation()
                                                    return _vm.addOrEditUnits(
                                                      unit
                                                    )
                                                  },
                                                  keyup: [
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "right",
                                                          39,
                                                          $event.key,
                                                          [
                                                            "Right",
                                                            "ArrowRight"
                                                          ]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      if (
                                                        "button" in $event &&
                                                        $event.button !== 2
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusUnitDeleteButton.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "left",
                                                          37,
                                                          $event.key,
                                                          ["Left", "ArrowLeft"]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      if (
                                                        "button" in $event &&
                                                        $event.button !== 0
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusUnitList.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "up",
                                                          38,
                                                          $event.key,
                                                          ["Up", "ArrowUp"]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusUnitList.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "down",
                                                          40,
                                                          $event.key,
                                                          ["Down", "ArrowDown"]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusUnitList.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    }
                                                  ]
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("fal fa-pencil")]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                ref:
                                                  "uDeleteButton" + _vm.index,
                                                refInFor: true,
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "error",
                                                  icon: "",
                                                  small: "",
                                                  "aria-label": _vm.$t(
                                                    "deleteLabel"
                                                  )
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.deleteUnit(
                                                      unit,
                                                      true
                                                    )
                                                  },
                                                  keyup: [
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      $event.stopPropagation()
                                                      return _vm.deleteUnit(
                                                        unit,
                                                        true
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "left",
                                                          37,
                                                          $event.key,
                                                          ["Left", "ArrowLeft"]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      if (
                                                        "button" in $event &&
                                                        $event.button !== 0
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusUnitEditButton.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "up",
                                                          38,
                                                          $event.key,
                                                          ["Up", "ArrowUp"]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusUnitList.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "down",
                                                          40,
                                                          $event.key,
                                                          ["Down", "ArrowDown"]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusUnitList.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    }
                                                  ]
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("fal fa-trash-alt")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _vm._l(_vm.localClassUnits, function(
                                    unit,
                                    index
                                  ) {
                                    return _c("v-hover", {
                                      key: index,
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var hover = ref.hover
                                              return [
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.filterUnits(
                                                          unit
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      {
                                                        staticClass:
                                                          "drop-down-list"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "mt-2"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                unit.unitNum
                                                              ) +
                                                                " - " +
                                                                _vm._s(
                                                                  unit.unitTitle
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        ref:
                                                          "uEditButton" +
                                                          (_vm.sharedClassUnits
                                                            .length +
                                                            index),
                                                        refInFor: true,
                                                        staticClass: "ml-2",
                                                        attrs: {
                                                          icon: "",
                                                          small: "",
                                                          "aria-label": _vm.$t(
                                                            "editLabel"
                                                          )
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.addOrEditUnits(
                                                              unit
                                                            )
                                                          },
                                                          keydown: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            $event.stopPropagation()
                                                            return _vm.addOrEditUnits(
                                                              unit
                                                            )
                                                          },
                                                          keyup: [
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "right",
                                                                  39,
                                                                  $event.key,
                                                                  [
                                                                    "Right",
                                                                    "ArrowRight"
                                                                  ]
                                                                )
                                                              ) {
                                                                return null
                                                              }
                                                              if (
                                                                "button" in
                                                                  $event &&
                                                                $event.button !==
                                                                  2
                                                              ) {
                                                                return null
                                                              }
                                                              return _vm.focusUnitDeleteButton.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "left",
                                                                  37,
                                                                  $event.key,
                                                                  [
                                                                    "Left",
                                                                    "ArrowLeft"
                                                                  ]
                                                                )
                                                              ) {
                                                                return null
                                                              }
                                                              if (
                                                                "button" in
                                                                  $event &&
                                                                $event.button !==
                                                                  0
                                                              ) {
                                                                return null
                                                              }
                                                              return _vm.focusUnitList.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "up",
                                                                  38,
                                                                  $event.key,
                                                                  [
                                                                    "Up",
                                                                    "ArrowUp"
                                                                  ]
                                                                )
                                                              ) {
                                                                return null
                                                              }
                                                              return _vm.focusUnitList.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "down",
                                                                  40,
                                                                  $event.key,
                                                                  [
                                                                    "Down",
                                                                    "ArrowDown"
                                                                  ]
                                                                )
                                                              ) {
                                                                return null
                                                              }
                                                              return _vm.focusUnitList.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            }
                                                          ]
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { small: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fal fa-pencil"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        ref:
                                                          "uDeleteButton" +
                                                          (_vm.sharedClassUnits
                                                            .length +
                                                            index),
                                                        refInFor: true,
                                                        staticClass: "ml-2",
                                                        attrs: {
                                                          color: "error",
                                                          icon: "",
                                                          small: "",
                                                          "aria-label": _vm.$t(
                                                            "deleteLabel"
                                                          )
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.deleteUnit(
                                                              unit,
                                                              false
                                                            )
                                                          },
                                                          keyup: [
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              ) {
                                                                return null
                                                              }
                                                              $event.stopPropagation()
                                                              return _vm.deleteUnit(
                                                                unit,
                                                                false
                                                              )
                                                            },
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "left",
                                                                  37,
                                                                  $event.key,
                                                                  [
                                                                    "Left",
                                                                    "ArrowLeft"
                                                                  ]
                                                                )
                                                              ) {
                                                                return null
                                                              }
                                                              if (
                                                                "button" in
                                                                  $event &&
                                                                $event.button !==
                                                                  0
                                                              ) {
                                                                return null
                                                              }
                                                              return _vm.focusUnitEditButton.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "up",
                                                                  38,
                                                                  $event.key,
                                                                  [
                                                                    "Up",
                                                                    "ArrowUp"
                                                                  ]
                                                                )
                                                              ) {
                                                                return null
                                                              }
                                                              return _vm.focusUnitList.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "down",
                                                                  40,
                                                                  $event.key,
                                                                  [
                                                                    "Down",
                                                                    "ArrowDown"
                                                                  ]
                                                                )
                                                              ) {
                                                                return null
                                                              }
                                                              return _vm.focusUnitList.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            }
                                                          ]
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { small: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fal fa-trash-alt"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                {
                  staticClass: "rounded-0",
                  staticStyle: { "margin-top": "17px" },
                  attrs: { flat: "" }
                },
                [
                  _c("v-card-title", { staticClass: "hidden-md-and-up" }),
                  _c("lesson-lists-table", {
                    ref: "lessonListsTable",
                    attrs: {
                      items: _vm.tableLessonBankItems,
                      search: _vm.search,
                      excludeHeaders: ["className", "dateLabel", "dayLabel"]
                    },
                    on: { dragDrop: _vm.moveSequence }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }